import lottie from "lottie-web";
import { Sticky } from "sticky-ts";

// -----------------------------------------------------------
// Header
// -----------------------------------------------------------

let header: HTMLElement | null = null;
// 色をつける閾値
const threshold = 64;
// 初期状態のときのみ、ヘッダーの背景を透明にする
const headerBackground = () => {
  const main = () => {
    if (header === null) {
      header = document.getElementById("header");
    }
    if (!header) return;
    // 画面のスクロール量を取得する
    const scrollTop = document.documentElement.scrollTop;
    if (scrollTop > threshold) {
      header.classList.remove("style-transparent");
    } else {
      header.classList.add("style-transparent");
    }
  };
  window.addEventListener("scroll", () => {
    main();
  });
  main();
};
document.addEventListener("DOMContentLoaded", headerBackground);

// -----------------------------------------------------------
// lottie
// -----------------------------------------------------------
document.addEventListener("DOMContentLoaded", async () => {
  await loadLottie();
});

const loadLottie = async () => {
  // first view
  const firstView = await import("@/javascripts/lottie/home_first_view.json");
  await attachLottie("lottie_home_first_view", firstView);

  // topic
  const topicsInstantly = await import("@/javascripts/lottie/home_topics_instantly.json");
  await attachLottie("lottie_home_topics_instantly", topicsInstantly);

  const topicsBusiness = await import("@/javascripts/lottie/home_topics_business.json");
  await attachLottie("lottie_home_topics_business", topicsBusiness);

  // ui preview
  const uiPreview = await import("@/javascripts/lottie/home_ui_preview.json");
  await attachLottie("lottie_home_ui_preview", uiPreview);
};
const attachLottie = async (id: string, animationData: any) => {
  const container = document.getElementById(id);
  if (container) {
    lottie.loadAnimation({
      container: container, // index.htmlで指定したid
      renderer: "svg", // 描画形式
      loop: true, // true→ループ/false→1回
      autoplay: true, // 自動再生
      animationData: animationData.default,
      rendererSettings: {
        filterSize: {
          width: "200%",
          height: "400%",
          x: "-50%",
          y: "-50%",
        },
      },
    });
  }
};
// -----------------------------------------------------------
// メニュー固定
// -----------------------------------------------------------

document.addEventListener("DOMContentLoaded", () => {});

const businessReviewMenu = () => {
  // h3が画面のある一定の高さを超えたら、その次のh3クラスの文章に対して、メニューをactiveにする
  const root = document.querySelectorAll("[data-sticky-container]");
  if (root.length === 0) return;
  const menusRoot = root[0].querySelectorAll("[data-sticky-menu]");
  if (menusRoot.length === 0) return;
  const menus = menusRoot[0].querySelectorAll("li");
  // root配下のh3タグを取得
  const h3s = root[0].querySelectorAll("h3");
  // topの閾値
  const threshold = 200;
  let sticky: Sticky | null = null;

  //
  // mounted
  //
  const initialize = () => {
    sticky = new Sticky("[data-sticky-menu]");
  };

  //
  // メインロジック
  //
  const main = () => {
    let targetIndex = h3s.length - 1;

    for (let i = 0; i < h3s.length; i++) {
      // 高さを取得
      const h3 = h3s[i];
      const h3Rect = h3.getBoundingClientRect();
      const top = h3Rect.top;
      if (top >= threshold) {
        targetIndex = i - 1;
        if (targetIndex < 0) {
          targetIndex = 0;
        }
        break;
      }
    }
    const targetMenu = getTargetMenu(h3s[targetIndex]);
    if (!targetMenu) {
      return;
    }
    // すでに付与されているなら何もしない
    if (targetMenu.classList.contains("style-active")) {
      return;
    }
    // アクティブのクラスを付与する。
    menus.forEach((menu) => {
      menu.classList.remove("style-active");
    });
    targetMenu.classList.add("style-active");
  };

  //
  // utils
  //
  // 対象のメニューを取得する
  const getTargetMenu = (h3: HTMLElement) => {
    // h3のテキストと同じテキストを持つメニューを取得する
    const text = h3.innerText;
    const altText = h3.getAttribute("tag");
    // li要素を取得する
    for (let i = 0; i < menus.length; i++) {
      const menu = menus[i];
      if (menu.innerText === text) {
        return menu;
      }
      if (menu.innerText === altText) {
        return menu;
      }
    }
    return null;
  };

  //
  // addEventListener
  //
  // 画面のスクロールイベント
  window.addEventListener("scroll", () => {
    main();
  });
  // リサイズイベント
  window.addEventListener("resize", () => {
    if (sticky !== null) {
      sticky.update();
    }
  });

  //
  // onMounted
  //
  initialize();
  main();
};
// DOMの読み込み完了後に実行する
document.addEventListener("DOMContentLoaded", businessReviewMenu);
